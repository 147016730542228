import React from "react";
import WHomeContent from "../components/Home";

const HomePageWeb = () => {

    return(
        <WHomeContent />
    )
}

export default HomePageWeb;