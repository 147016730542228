import React from "react";
import WWriterContent from "../components/WriterPage";

const WriterPageWeb = () => {

    return(
        <WWriterContent />
    )
}

export default WriterPageWeb;